.product-layout {
    border-right:1px solid $border-color;
    border-bottom:1px solid $border-color;
    background: $white;
    padding-top:10px;
    padding-bottom:20px;
    &.col-md-3 {
        &:nth-child(4n+0) {
            border-right-color:transparent;
            border-bottom:1px solid $border-color;
        }
    }
    &.col-md-4 {
        &:nth-child(3n+0) {
            border-right-color:transparent;
            border-bottom:1px solid $border-color;
        } 
    }
    &.col-md-6 {
        &:nth-child(2n+0) {
            border-right-color:transparent;
            border-bottom:1px solid $border-color;
        } 
    }
    &.col-md-12 {
        border-right-color:transparent;
        border-bottom:1px solid $border-color;
    }
}
.product-thumb {
    position: relative;
    background: $white;
    h4 {
        font-weight: $product-name-font-weight;
        font-size: $product-name-font-size;
        font-family:$product-font-family;
        margin: 0px;
        line-height: 18px;
        overflow: hidden;
        a{
            color: $product-name-color;
            &:hover{
                color: $product-name-hover-color;
            }
        }
    }
    .image {
        background-color: $white;
        position: relative;
        text-align: center;
        z-index: 0;
        margin: 0 auto;
        overflow: hidden;
    }
    .group-action {
        @include transition (all .35s ease 0s);
        display: flex;
        padding-top:10px;
        .btn-cart {
            background: $theme-color-second;
            color: $white;
            @include border-radius(3px);
            @include button-effect (#fff, #fff, $theme-color, $theme-color-second); 
        }
        .btn-wishlist,
        .btn-compare {
            margin-left:5px;
            border: 1px solid $border-color;
            @include border-radius(3px);
            @include button-effect ($theme-color-second, #fff, $theme-color, transparent); 
            span {
                display:none;
            }
        }
    }

    .caption {
        position: relative;
        z-index: 12;
    }
    .product-meta {
        padding: 10px 0;
        position: relative;
    } 
    
    .description {
        font-size: $product-name-font-size - 1;
        @extend .hide;
    }
    .price {
        // all page
        color: $product-price-color;
        font-weight: $product-price-font-weight;
        font-size: $product-price-font-size;
        font-family: $product-price-font-family;
        margin-bottom: 10px;
        height: 25px;
        .price-old {
            color: $product-price-old-color;
            font-weight: $product-price-old-font-weight;
            font-size: $product-price-old-font-size;
            padding: 0 5px;
            text-decoration: line-through;
        }
        .price-tax {
            display: none;
        }
        .price-new {
            color: $price-new;
        }
    }
    .rating {    
        border-top:1px solid $border-color;
        border-bottom:1px solid $border-color;
    }
    
    .carousel-controls-v1 {
        width: 80px;
        height: 35px;
        position: absolute;
        bottom: 0%;
        left:45%;
        margin-left: -30px;
        .carousel-control {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            @include border-radius(3px);
            @include size(35px, 35px);
            @include button-effect ($theme-color-second, #fff, $theme-color, #fff); 
        }
    }
}

.product-list { 
    .description {
        display: block !important;
    }
}

.product-list-sidebar {
    @extend .product-list;
    .product-layout {
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
        .group-action,
        .product-label { 
            display: none;
        }
        .product-meta {
            padding-left: 8px !important;
        }
    }
}

/* Quick View */
.pav-quickview { 
    position: absolute;
    z-index: 10;
    border-color: $border-color;
    background: transparent !important;
    border-radius:0 !important;
    a {
        @extend .btn;
        @include size(40px, 40px);
        @include transition (all .35s ease 0s);
        @include border-radius(3px!important);
        @include button-effect ($theme-color-second, #fff, $theme-color, #fff);
        line-height:30px; 
    }        
}
.product-list {
    .product-thumb {
        overflow: hidden;
        display: flex;
        align-items: center;
        .product-meta {
            text-align: left;
            width: 60%;
            padding: 0;
            padding-left: 30px;
        }
        .image {
            margin-bottom: 0;
            width: 40%;
        }
        .btn-wishlist,
        .btn-compare {
            width:auto;
            span { display: inline-block !important;}
        }
    }
}
.product-latest, .product-featured, .product-listing-tabs {
    padding-top: 50px;
    .panel-heading{
        position: relative;
        padding-bottom: 50px;
        &:after{
            background: transparent url("#{$image-theme-path}icon1.png") no-repeat scroll 0 0;
            top: 60px;
            content: "";
            width: 30px;
            height: 15px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
        }
    } 
}
.product-latest {
    border-bottom: 1px solid $border-color;    
}

.rating {
    .fa-stack {
        .fa-star {
            color: #ff6346;
            + .fa-star-o{
                display: none;
            }
        }
        .fa-star-o{
            color:$gray-light;
        }
        &:hover{
            cursor: pointer;
        }
    }
}

.fa-stack {
    width:0.8em; height:0.8em; line-height:0.8em;
    .fa-stack-2x {
        font-size:0.8em;
    }
}

.rating-wrapper {
    .fa-star{
        display: none;
        color: #ff6346;
    }

    .fa-star-o{
        color:$gray-light;
    }
    .fa-stack {
        &.active,
        &.selected{
            .fa-star{
                display: block;
            }
            .fa-star-o { display:none}
        }
    }
}

/*** PavProductCarousel ***/


/** product-listing-tabs **/
.product-listing-tabs {
    .tab-heading {
        text-align: center;
        .nav-tabs {
            border: none;
            li {
                float: inherit;
                display: inline-block;
                padding-bottom: 10px;
                a {
                    text-transform: uppercase;
                    font-size: $font-size-base + 2;
                    border: 1px solid $border-color;
                    color: $theme-color;
                    font-weight: 600;
                    margin: 0px;
                    @include transition (all .35s ease 0s);
                }
                &.active, 
                &:hover,
                &:focus {
                    a{ 
                        background-color: $theme-color;
                        color: $white;
                    }
                }                   
            }
        }
    }
}


/** Label **/

.product-label-percentage, 
.product-label {
    color: $product-label-color;
    font-size: $product-label-font-size;
    font-weight: $product-label-font-weight;
    font-family: $product-label-font-family;
    @include size(44px,33px);
    @include border-radius(50%);
    line-height: $product-label-line-height;
    position: absolute;
    text-align: center;
    text-transform: $label-transform;
    top: 0;
    right:10px;
    z-index: 2; 
    &:before {
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        bottom: 4px;
        content: "";
        display: inline-block;
        position: absolute;
        z-index: 0;
    }   
    &.product-label-new{
        left: 0px;
        border-right: 46px solid transparent;   
        border-top: 46px solid $label-sale-bg;
        span{            
            left: 5px;          
            @include rotate(-50deg);  
        }           
    }
    &.product-label-special{
        background: $label-sale-bg;
        &:before {
            border-bottom: 7px solid $label-sale-bg;
            left: -3px;
        }  
    }
}
.product-label-discount { display:none;}
.product-label-special + .product-label-discount {
    right: 100px;
    background: #44cc9e;
    color: $white;
    span {
        border-color: $white;
    }
}

.product-nav-item {
    position:fixed;
    top:40%;
    .btn {
        width: 30px;
        height: 83px;
        padding: 0;
        z-index: 1000;
        position: relative;
        span {
            font-family: $font-icon;
            font-size: 30px;
        }
    }
    img { 
        float:left;
        width: 60px;
    }
    .price {
        color: $theme-color;
    }
    .dropdown {
        .dropdown-menu { 
            min-width: 300px; 
            display: block;
            visibility: hidden;
            z-index: 100;
            margin: 0;     
             @include box-shadow(none)  ;       
            @include transition(all 0.35s ease 0s);
        }
        &:hover {
            .dropdown-menu { 
                visibility: visible;
            }
        }
    }
    &.product-next {
        right: 0;
        .dropdown {
            .dropdown-menu {
                right: 30px;
                top:0px;
                left: auto;                 
                @include transform(translate3d(300px, 0, 0));        
            }
            &:hover {
                .dropdown-menu { 
                    @include transform(translate3d(0px, 0, 0));
                }
            }
        }
        .btn span {            
            &:before {
                content: "\f104";
            }
        }
    }
    &.product-prev {
        left: 0;
        .dropdown {
            .dropdown-menu {
                left: 30px;
                top:0px;   
                @include transform(translate3d(-300px, 0, 0));                
            }
            &:hover {
                .dropdown-menu { 
                    @include transform(translate3d(0px, 0, 0));
                }
            }
        }
        .btn span {            
            &:before {
                content: "\f105";
            }
        }
    }
}
/** product block style **/

.product-image-swap {
    position:relative;
    overflow: hidden;
    img {
        height: auto;
        max-width: 100%;
    }
    .image-hover { 
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;

    }
    .image-effect {
        top:0;position:absolute;
        overflow:hidden;
        left:0;
        right:auto;
        opacity:1;
        filter:alpha(opacity=100);
        -webkit-transform:translate(-100%,0);
        -ms-transform:translate(-100%,0);
        -o-transform:translate(-100%,0);
        transform:translate(-100%,0);
        -webkit-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s
    }
    &:hover .image-effect {-webkit-transform:translate(0,0);-ms-transform:translate(0,0);-o-transform:translate(0,0);transform:translate(0,0)}
    &:hover .image-hover {-webkit-transform:translate(100%,0);-ms-transform:translate(100%,0);-o-transform:translate(100%,0);transform:translate(100%,0);opacity:0;visibility:visible;z-index:-1}
}    

.product-image-slide {
    .carousel-controls-v1 {
        @include opacity(0);
        @include transition(all .3s);
    }
    &:hover {
        .carousel-controls-v1 {
            @include opacity(1);
        }
    }
}